import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Button } from './Button';
import i18next, { t } from 'i18next';


const LanguageSelection = (props) => {

	const [open, setOpen] = useState(false);

	const languageCode = useMemo(() => {
		return 'NL';
	});

	const setLanguage = (language) => {
		localStorage.setItem('i18nextLng', language);
		setOpen(false);
		location.reload();
	};
	return (
		<Container onClick={() => setOpen(e => !e)} open={open} {...props}>
			{i18next.language}
			{open && <Content>
				<div onClick={() => setLanguage('nl')}>Nederlands</div>
				<div onClick={() => setLanguage('en')}>English</div>
			</Content>}
		</Container>
	);
};

// sc

const Container = styled(Button)`
	position: relative;
	background-color: ${p => p.open && p.theme.colors.primary};
	color: ${p => p.open && 'white'};
	height: 100%;
	aspect-ratio: 1/1;
	margin-left: auto;
	margin-right: 25px;

	border-inline: 1px solid ${p => p.theme.colors.neutral};

	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 2em;
`;

const Content = styled.div`

	position: absolute;
	inset-block-start: 0;
	inset-inline-start: 0;
	background-color: ${p => p.theme.colors.neutralLight};
	color: ${p => p.theme.colors.neutralDarkest};
	font-family: 'ZillaSlab';
	font-size: 0.8em;
	text-decoration: unset;
	translate: 0 -100%;

	display: flex;
	align-items: flex-start;
	flex-direction: column;
	

	z-index: 3;


	& > div {
		cursor: pointer;
		padding: 10px;
		width: 100%;
		text-align: start;

		&:hover {
			/* text-decoration: underline; */
			color: ${p => p.theme.colors.neutralLightest};
			background-color: ${p => p.theme.colors.primary};
		}
	}


`;

export default LanguageSelection;